// jshint strict:false, undef:false, unused:false

/*/
	Cremastrap BS4 Plugins
	Stephen Ginn at Crema Design Studio
	Last Updated on 2020-07-20
/*/

// CREMASTRAP HELPERS:
import '/Sites/cremastrap-bs4/plugins/crema/js/functions/getUrlParameter.js';
import "/Sites/cremastrap-bs4/plugins/crema/js/functions/serialize.js";

function addScript(src) {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    document.body.appendChild(script);
}
