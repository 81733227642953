/*/
	Forms
	Stephen Ginn at Crema Design Studio
	Last Updated on 2020-05-19
/*/

// Retrieves input data from a form and returns it as a JSON object:
// https://stackoverflow.com/a/49826736

// var form = document.querySelector("form")
// formToJSON(form)

// Retrieves input data from a form and returns it as a JSON object:
// This might have an issue with checkboxes though?
const formToJSON = form => Object.fromEntries(new FormData(form));

// Same as function above, but doesn't have issues with checkboxes
function getValues(form) {
	var object = {};

	new FormData(form).forEach((value, key) => {
		if (Object.prototype.hasOwnProperty.call(object, key)) {
			var current = object[key];
			current = (Array.isArray(current) ? current : object[key] = [current]);
			current.push(value);
		} else {
			object[key] = value;
		}
	});

	return object;
}

// Based on https://stackoverflow.com/a/62010324
var getValues2 = form => {
	const fd = new FormData(form);

	return Object.fromEntries(Array.from(fd.keys()).map(key => [
		key, fd.getAll(key).length > 1 ? fd.getAll(key) : fd.get(key)
	]))
}

const getQueries = form => {
	return Object.values(getValues(form)).flat().filter(d => d);
}

// Get Shopify Friendly URL String
const getUrlString = data => Object.entries(data).map(e => e.join('=')).join('&');
